<template>
<Header />
  <div class="wrap">
    <div class="content">
      <div class="intro">
        <div class="">《用户服务协议》</div>
        <div class="">生效于2021年10月5日</div>
        <div class="">欢迎使用中国杨凌农业高新科技成果博览会云上展会服务！</div>
        <div class="">
          您在使用中国杨凌农业高新科技成果博览会云上展会服务前请仔细阅读、充分理解《用户服务协议》（以下简称“本协议“）及《中国杨凌农业高新科技成果博览会云上展会隐私权政策》（以下简称”隐私权政策“）的条款和条件，<span
            class="focus"
            >特别是免除或者限制责任的条款、争议解决和法律适用条款。免除或者限制责任的条款将以粗体字显示，您应重点阅读。</span
          >
        </div>
        <div class="">
          当您按照注册页面提示填写信息，勾选“我已阅读并同意《用户服务协议》和《中国杨凌农业高新科技成果博览会云上展会隐私权政策》后，或您以其他中国杨凌农业高新科技成果博览会云上展会平台允许的方式实际使用中国杨凌农业高新科技成果博览会云上展会服务时，即表示您已充分阅读、理解并接受本协议的全部内容，本协议即产生法律约束力。
        </div>
        <div class="">
          本协议适用于您访问和使用中国杨凌农业高新科技成果博览会云上展会PC及WAP端网页、中国杨凌农业高新科技成果博览会云上展会PC和移动客户端、H5推广页、小程序（统称为“中国杨凌农业高新科技成果博览会云上展会平台”）。本协议是您（以下简称为“您”或“用户”）与中国杨凌农高会筹委会（以下简称为“我们”或“杨凌农高会筹委会”）共同缔结。
        </div>
      </div>
      <div class="first-para mt">
        <div class="">1.本协议的适用和接受</div>
        <div class="">
          1.1您使用中国杨凌农业高新科技成果博览会云上展会中国杨凌农业高新科技成果博览会云上展会平台所提供的各种工具、内容和服务（以下统称为“中国杨凌农业高新科技成果博览会云上展会服务”），包括但不限于对中国杨凌农业高新科技成果博览会云上展会平台的访问、注册账号、登录、浏览、上传和展示展品、装修线上展厅、发起和观看视频直播和录播、发起或参与线上会议论坛、订阅推广营销邮件、查询展商和展品信息、交换联系信息、发送询盘沟通等行为，表示您已充分阅读、理解并自愿接受本协议的全部内容并受其约束。如果您不接受本协议内容，您应当立即停止访问或使用中国杨凌农业高新科技成果博览会云上展会平台服务。
        </div>
        <div class="">
          1.2本协议内容包括本协议正文以及中国杨凌农业高新科技成果博览会云上展会平台官方网站不时发布的规则、规范、政策、说明、通知、公告、声明（以下简称为“中国杨凌农业高新科技成果博览会云上展会平台规则”）。前述规则为本协议不可分割的组成部分，与本协议具有同等法律效力。
        </div>
        <div class="">
          1.3如果根据中华人民共和国法律和/或其他适用法律（包括您居住的国家/地区法律）：（1）您不具备完全民事权利能力和与所从事的民事行为相适应的行为能力，且未就使用中国杨凌农业高新科技成果博览会云上展会平台服务获得您法定监护人的同意或者；（2）您被禁止或限制使用中国杨凌农业高新科技成果博览会云上展会平台服务，则您不得使用中国杨凌农业高新科技成果博览会云上展会平台服务，否则，我们有权注销您的账户，相应后果和责任由您及/或您的监护人自行承担。如您代表一家企业、组织机构或其他法律主体使用中国杨凌农业高新科技成果博览会云上展会平台服务，则您声明和保证，您已经获得充分授权并有权代表该企业、组织机构或法律主体使用中国杨凌农业高新科技成果博览会云上展会平台服务，并受本协议约束。
        </div>
        <div class="">
          1.4我们根据需要可随时修订或更新本协议的全部或部分内容，修订或更新后协议将通过中国杨凌农业高新科技成果博览会云上展会平台官方网站提前7天公告的形式通知您并于公告期满后生效。如您不同意相关变更，应当立即停止使用中国杨凌农业高新科技成果博览会云上展会平台服务；如您在本协议变更后继续使用中国杨凌农业高新科技成果博览会云上展会平台服务，即表示您同意接受经修订或更新的协议。
        </div>
        <div class="">
          1.5如您选择中国杨凌农业高新科技成果博览会云上展会平台提供的其他附加服务，可能需要与我们或我们的关联公司、合作方签订单独的服务采购协议（统称为“附加协议”）。如果本协议与附加协议之间存在任何冲突或不一致，则仅就该服务（或服务中的功能）而言，附加协议优先于本协议适用。
        </div>
      </div>
      <div class="second-para mt">
        <div class="">2.用户资格</div>
        <div class="line">2.1主办方用户：</div>
        <div>
          2.1.1
          中国杨凌农业高新科技成果博览会云上展会平台注册主办方用户必须是有效存续的企业法人组织，并符合我们对主办方设定的资格审查标准；
        </div>
        <div>(1) 主办方应当依法持有有效市场主体资格登记；</div>
        <div>
          (2)
          主办方经营范围需包括展会组织，经营范围如包含依法需要取得相关经营主体或经营商品或服务经营许可的，应当依法取得经营相关商品和服务的审批许可；
        </div>
        <div>
          (3)
          主办方具备成功举办展会经验，具备从事专业展会开拓、组织、运营的专业人才，经过中国杨凌农业高新科技成果博览会云上展会服务提供方审核认可；
        </div>
        <div>
          (4)
          主办方指派或委托进行中国杨凌农业高新科技成果博览会云上展会平台主办方用户注册程序的经办人和在中国杨凌农业高新科技成果博览会云上展会平台代表主办方以该主办方名义从事经营活动的经办人必须具有主办方允许其代表行事的合法授权且系具备完全民事行为能力的自然人。
        </div>
        <div>
          2.1.2我们收到主办方申请资料后有权自行或授权第三方对申请注册主办方主体资格进行审查认证，主办方并有义务配合。主办方通过审查认证的，我们将应按本协议约定为主办方提供本协议项下的服务；主办方未通过审查认证的，我们有权中止或终止服务。
        </div>
        <div>
          2.1.3如主办方注册成功后出现不具备上述2.1.1、2.1.2款任一约定情形的，一经发现，我们有权立即终止或暂停该主办方在中国杨凌农业高新科技成果博览会云上展会平台账户的全部使用权限，由此造成的损失将由该主办方自行承担。
        </div>
        <div class="line">2.2观众用户</div>
        <div>
          2.2.1中国杨凌农业高新科技成果博览会云上展会平台观众用户应当是具备完全民事权利能力和与所从事的民事行为相适应的行为能力的自然人、法人或其他组织。若您不具备前述主体资格，请勿使用服务，否则我们有权冻结或注销您的账户。如您代表一家企业、组织机构或其他法律主体进行注册或以其他我们允许的方式实际使用本服务，则您声明和保证，您已经获得充分授权并有权代表该企业、组织机构或其他法律主体注册使用中国杨凌农业高新科技成果博览会云上展会服务，并受本协议的约束。
        </div>
        <div class="line"></div>
        <div>2.3展商用户</div>
        <div>
          2.3.1
          中国杨凌农业高新科技成果博览会云上展会平台注册展商用户必须合法注册且有效存续的企业法人，并符合我们或委托第三方对展商设定的资格审查标准；
        </div>
        <div>
          2.3.2
          展商用户经营范围如包含依法需要取得相关经营主体或经营商品或服务经营许可的，应当依法取得经营相关商品和服务的审批许可；
        </div>
        <div>
          2.3.3展商用户通过审核的，我们将为展商用户提供本协议项下的服务；展商用户未通过审查或通过后不再具备资格的，我们有权中止或终止服务。
        </div>
        <div>
          2.4在您申请注册账户后，我们将对您的注册信息进行审核，并可能出于任何风控、安全、合规等原因拒绝您的注册申请、限制或冻结未通过审核的账户。
        </div>
        <div class="">
          2.5在注册、使用和管理账户时，您保证您提交的材料或填写的信息真实、准确及完整。您理解并同意，您承诺注册使用的账号名称、头像和简介等信息中不得出现违法和不良信息，不得冒充他人，不得未经许可为他人注册，不得以可能导致其他用户误认的方式注册账号，不得使用可能侵犯他人权益的用户名（包括但不限于涉嫌商标权、名誉权侵权等），否则我们有权不予注册或停止服务并收回账号，因此产生的损失由您自行承担。
        </div>
        <div class="">
          2.6依照适用中国法律法规的规定，为使用中国杨凌农业高新科技成果博览会云上展会平台部分或全部服务功能，我们可能要求您需要填写真实的个人或企业身份信息，请您按照相关法律规定完成实名认证，并注意及时更新上述相关信息。若我们发现或有合理理由怀疑您提交的材料或提供的信息不真实、不准确、不完整或不合法，我们有权拒绝您的注册申请或停止为您提供相关服务，您可能无法使用中国杨凌农业高新科技成果博览会云上展会平台服务或在使用过程中部分功能受到限制。
        </div>
        <div class="">
          2.7您了解并同意，您在中国杨凌农业高新科技成果博览会云上展会平台注册的用户账号所有权归属于中国杨凌农业高新科技成果博览会云上展会平台，注册完成后，您仅获得账号使用权。中国杨凌农业高新科技成果博览会云上展会平台账号使用权归属于初始申请注册人，不得以任何方式转让或被提供予他人使用，否则，我们有权立即暂停或终止向该注册账号提供服务，直至注销或删除账号，由此带来的因您使用本服务产生的全部数据、信息等被清空、丢失等的损失，您应自行承担。
        </div>
        <div class="">
          2.8您有责任维护您的账户、密码的安全性与保密性，并对您以注册账户名义所从事的活动承担全部法律责任。您应高度重视对账户与密码的保密，在任何情况下不向他人透露账户及密码。若发现他人未经许可使用您的账户或发生任何其他安全漏洞时，您应当立即通知我们并更改密码。如果有理由怀疑第三方知道您的密码，或可能存在滥用的情形，我们有权在该情况得到澄清之前冻结您的账户或为安全目的采取其他必要措施。您知悉并同意，我们不对不可归责于我们的，因任何未经授权的人所从事的任何活动或用户账户信息的数据泄露而引起的或与之相关的任何损失或损害负责。
        </div>
        <div class="">
          2.9您理解并同意，我们提供的中国杨凌农业高新科技成果博览会云上展会平台服务是按照现有技术和条件所能达到的现状提供的。我们会尽最大努力向您提供服务，确保服务的连贯性和安全性。您理解，我们不能随时或始终预见和防范法律、技术以及其他风险，包括但不限于不可抗力、网络原因、第三方服务瑕疵、第三方网站等原因可能导致的服务中断、不能正常使用服务以及其他损失和风险。
        </div>
        <div class="">
          2.10您理解并同意，为了整体服务运营、平台运营安全的需要，我们有权视具体情况决定服务/功能的设置及其范围，修改、中断、中止或终止中国杨凌农业高新科技成果博览会云上展会平台全部或部分功能或服务。
        </div>
      </div>
      <div class="third-para mt">
        <div class="">3.用户信息的保护</div>
        <div class="">
          3.1对于您的个人信息，包括但不限于您的姓名、身份证明号码、联系方式、企业经营及客户信息、个人生物识别信息等，我们将严格按照我们发布的《隐私权政策》进行收集、使用、保存、共享、转让、公开披露以及保护。
        </div>
        <div class="">
          3.2您理解并同意，中国杨凌农业高新科技成果博览会云上展会平台在向您提供服务过程中收集您个人信息以外的其他信息，包括但不限于企业信息、行业、经营状况、客户信息等；我们可能会从第三方间接获得前述相关数据；我们将对直接或间接收集的信息进行统计分析，以优化展品展示及直播效果、实现智能推送、进行后续研究或进行商业上合理范围内的利用；我们将根据相关法律法规、法律程序、相关政府机关的强制性要求以及向您提供服务的需要，公开披露该等信息或将该等信息与第三方分享。
        </div>
      </div>
      <div class="forth-para mt">
        <div class="">4.第三方网站及应用</div>
        <div class="">
          4.1中国杨凌农业高新科技成果博览会云上展会平台中可能设有向第三方网站、平台的跳转链接，也不排除其他用户上传或发布的内容中添加了指向外部网站的链接。用户应仔细阅读这些外部网站的使用条款及隐私政策。我们不对外部网站的真实性、合法性或安全性（包括数据泄露等）承担任何责任。
        </div>
        <div class="">
          4.2您在访问中国杨凌农业高新科技成果博览会云上展会平台或使用中国杨凌农业高新科技成果博览会云上展会服务中使用第三方提供的应用、软件或服务时，除遵守中国杨凌农业高新科技成果博览会云上展会平台规则外，还可能需要同意并遵守第三方的协议或规则。如因第三方应用、软件及相关服务产生的争议、损失或损害，由您自行与第三方解决，我们并不就此而对您或任何第三方承担任何责任。
        </div>
      </div>
      <div class="fifth-para mt">
        <div>5.用户行为规范</div>
        <div>5.1一般行为规范</div>
        <div>
          您访问中国杨凌农业高新科技成果博览会云上展会平台及/或使用中国杨凌农业高新科技成果博览会云上展会平台服务时不得从事下列行为：
        </div>
        <div>（1）冒充任何人或机构，或以虚伪不实的方式陈述或谎称与任何人或机构有关；</div>
        <div>（2）非法侵入网络、干扰网络正常功能、窃取网络数据等危害网络安全的活动；</div>
        <div>
          （3）提供专门用于从事侵入网络、干扰网络正常功能及防护措施、窃取网络数据等危害网络安全活动的程序、工具；
        </div>
        <div>
          （4）明知他人从事危害网络安全的活动的，为其提供技术支持、广告推广、支付结算等帮助；
        </div>
        <div>
          （5）使用未经我们授权或许可的任何插件、外挂、系统或第三方工具对中国杨凌农业高新科技成果博览会云上展会平台服务的正常运行进行控制、干扰、破坏、修改或施加其他影响；
        </div>
        <div>（6）使用未经许可的数据或进入未经许可的服务器/账户；</div>
        <div>
          （7）未经允许对计算机信息网络中存储、处理或者传输的数据和应用程序进行删除、修改或者增加的；
        </div>
        <div>（8）故意制作、传播计算机病毒等破坏性程序；</div>
        <div>
          （9）违反法律法规、规章、政策、规范性文件、本协议及侵犯他人合法权益的其他行为。
        </div>
        <div>5.2信息内容展示规范</div>
        <div>
          您制作、复制、发布、传播的信息应当符合适用法律法规的要求。您同意并承诺不制作、复制、发布、传播下列信息：
        </div>
        <div>（1）反对中华人民共和国宪法所确定的基本原则的；</div>
        <div>
          （2）危害中华人民共和国国家安全，泄露中华人民共和国国家秘密，颠覆中华人民共和国国家政权，破坏中华人民共和国国家统一的；
        </div>
        <div>（3）损害中华人民共和国国家荣誉和利益的；</div>
        <div>
          （4）煽动中华人民共和国民族仇恨、民族歧视，破坏中华人民共和国民族团结的；
        </div>
        <div>（5）破坏中华人民共和国国家宗教政策，宣扬邪教和封建迷信的；</div>
        <div>（6）散布谣言，扰乱社会秩序，破坏社会稳定的；</div>
        <div>（7）散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；</div>
        <div>（8）侮辱或者诽谤他人，侵害他人合法权益的；</div>
        <div>
          （9）歪曲、丑化、亵渎、否定英雄烈士事迹和精神，以侮辱、诽谤或者其他方式侵害英雄烈士的姓名、肖像、名誉、荣誉的；
        </div>
        <div>
          （9）歪曲、丑化、亵渎、否定英雄烈士事迹和精神，以侮辱、诽谤或者其他方式侵害英雄烈士的姓名、肖像、名誉、荣誉的；
        </div>
        <div>（10）宣扬恐怖主义、极端主义或者煽动实施恐怖活动、极端主义活动的；</div>
        <div>（11）损害中华人民共和国国家机关信誉的；</div>
        <div>
          （12）任何假冒伪劣商品或服务的信息，包括但不限于侵犯他人知识产权的商品、不符合相关国家标准或行业标准的商品、虚假服务等信息；
        </div>
        <div class="">
          （13）其他违反适用法律法规、政策及公序良俗、干扰中国杨凌农业高新科技成果博览会云上展会平台正常运营或侵犯其他用户或第三方合法权益内容的信息；
        </div>
        <div class="">
          （14）包含任何直接或间接指向任何其他网站的链接，其中包含任何可能违反本协议的内容；
        </div>
        <div class="">
          （15）在中国杨凌农业高新科技成果博览会云上展会平台以任何形式展示、提供其他展览会、专业批发市场或其他提供卖家注册和第三方贸易撮合平台功能的电子商务网站的链接和资料；
        </div>
        <div class="">
          （16）在中国杨凌农业高新科技成果博览会云上展会平台为其他线上或线下展览会、展销会进行任何形式的宣传活动。
        </div>
        <div class="">5.3信息内容使用规范</div>
        <div class="">
          未经我们事先书面许可，任何用户均不得自行或授权、允许、协助他人对中国杨凌农业高新科技成果博览会云上展会平台及其他产品或服务中的信息内容（包括但不限于主办方信息、参展商信息、观展用户信息、展品信息、展馆信息、各类统计数据）进行如下行为：
        </div>
        <div class="">
          （1）复制、下载、读取、编译、采用中国杨凌农业高新科技成果博览会云上展会平台的信息内容，用于包括但不限于宣传、增加阅读量、浏览量等商业性使用；
        </div>
        <div class="">
          （2）擅自编辑、整理、编排中国杨凌农业高新科技成果博览会云上展会平台服务的信息内容后在中国杨凌农业高新科技成果博览会云上展会平台以外的渠道进行展示；
        </div>
        <div class="">
          （3）采用包括但不限于特殊标识、特殊代码等任何形式的识别方法，自行或协助第三人对中国杨凌农业高新科技成果博览会云上展会平台服务的信息内容产生流量、阅读量引导、转移、劫持等不利影响；
        </div>
        <div class="">
          （4）以任何方式（包括但不限于盗链、冗余盗取、非法抓取、以数据爬虫技术获取、模拟下载、深度链接、假冒注册等）直接或间接盗取中国杨凌农业高新科技成果博览会云上展会平台服务的视频、图文等信息内容，或以任何方式（包括但不限于隐藏或者修改域名、平台特有标识、用户名等）删除或改变相关信息内容的权利管理电子信息；
        </div>
        <div class="">
          （5）其他非法获取或使用中国杨凌农业高新科技成果博览会云上展会平台服务的信息内容或侵害此类信息知识产权的行为。
        </div>
        <div class="">5.4广告</div>
        <div class="">
          5.4.1您使用中国杨凌农业高新科技成果博览会云上展会平台发布广告时，应当确保信息内容真实、准确，不含有虚假或者引人误解的内容，符合《中华人民共和国广告法》、《反不正当竞争法》及其他适用法律法规的规定。
        </div>
        <div class="">5.4.2广告不得有下列情形：</div>
        <div class="">
          （1）使用或者变相使用中华人民共和国的国旗、国歌、国徽，军旗、军歌、军徽；
        </div>
        <div class="">
          （2）使用或者变相使用中华人民共和国国家机关、国家机关工作人员的名义或者形象；
        </div>
        <div class="">（3）使用“国家级”、“最高级”、“最佳”等用语；</div>
        <div class="">
          （4）损害中华人民共和国国家的尊严或者利益，泄露中华人民共和国国家秘密；
        </div>
        <div class="">（5）妨碍社会安定，损害社会公共利益；</div>
        <div class="">（6）危害人身、财产安全，泄露个人隐私；</div>
        <div class="">（7）妨碍社会公共秩序或者违背社会良好风尚；</div>
        <div class="">（8）含有淫秽、色情、赌博、迷信、恐怖、暴力的内容；</div>
        <div class="">（9）含有民族、种族、宗教、性别歧视的内容；</div>
        <div class="">（10）妨碍环境、自然资源或者文化遗产保护；</div>
        <div class="">（11）法律、行政法规规定禁止的其他情形。</div>
        <div class="">
          5.4.3发布医疗、药品、医疗器械、农药、兽药和保健食品广告，以及适用法律、行政法规规定应当进行审查的其他广告，应当在发布前由广告审查机关对广告内容进行审查；未经审查，不得发布。
        </div>
        <div class="">
          5.4.4您理解并同意，在您访问中国杨凌农业高新科技成果博览会云上展会平台及使用中国杨凌农业高新科技成果博览会云上展会服务过程中，我们可能会向您推送具有相关性的参展商、展品或广告信息。
        </div>
        <div class="">
          5.4.5对于广告、推送或推广信息，您应当自行判断该广告或推广信息的真实性和可靠性并为自己的判断负责。除法律法规明确规定外，您应自行承担因该广告或推广信息进行的磋商、交易或因前述内容遭受的损害或损失，我们不予承担责任。
        </div>
      </div>
      <div class="sixth-para mt">
        <div class="">6.服务收费</div>
        <div class="">
          6.1
          我们保留在提前通知后对用户使用中国杨凌农业高新科技成果博览会云上展会相关服务进行收费的权利，您可以根据需要选择收费服务，具体内容以相关售卖协议或产品使用说明为准。
        </div>
      </div>
      <div class="seventh-para mt">
        <div class="">7.知识产权</div>
        <div class="">
          7.1您了解及同意，除非我们另行声明，本协议项下服务包含的所有产品、技术、软件、程序、数据及其他信息（包括但不限于文字、图像、图片、照片、音频、视频、图表、色彩、版面设计、电子文档）的所有知识产权（包括但不限于版权、商标权、专利权、商业秘密等）及相关权利均归中国杨凌农业高新科技成果博览会云上展会服务提供方所有。
        </div>
        <div class="">
          7.2您应保证，除非取得中国杨凌农业高新科技成果博览会云上展会服务提供方书面授权，对于上述权利您不得（并不得允许任何第三人）实施包括但不限于出租、出借、出售、散布、复制、修改、转载、汇编、发表、出版、还原工程、反向汇编、反向编译，或以其它方式发现原始码等的行为。
        </div>
        <div class="">
          7.3中国杨凌农业高新科技成果博览会云上展会平台的Logo、“中国杨凌农业高新科技成果博览会云上展会”等文字、图形及其组合，以及中国杨凌农业高新科技成果博览会云上展会平台其他标识、徽记、中国杨凌农业高新科技成果博览会云上展会平台服务的名称等为中国杨凌农业高新科技成果博览会云上展会提供方公司及其关联公司在中国和其他国家的注册商标。未经中国杨凌农业高新科技成果博览会云上展会服务提供方及其关联公司书面授权，任何人不得以任何方式展示、使用或做其他处理（包括但不限于复制、传播、展示、镜像、上传、下载），也不得向他人表明您有权展示、使用或做其他处理。
        </div>
        <div class="">
          7.4您理解并同意授权杨凌农高会筹委会在宣传和推广中使用您的名称、商标、标识，但仅限于表明您属于我们的客户或合作伙伴。
        </div>
        <div class="">
          7.5您理解并承诺，您以及你的客户、代理人在使用中国杨凌农业高新科技成果博览会云上展会平台服务时上传、发布或传输的内容（包括但不限于文字、图片、视频、音频等各种形式的内容及其中包含的音乐、声音、台词、视觉设计、对话等所有组成部分）均由您原创或已获合法授权（且含转授权）。您通过中国杨凌农业高新科技成果博览会云上展会平台上传、发布所产生内容的知识产权归属您或原始著作权人所有。
        </div>
        <div class="">
          7.6除非我们另行明确说明，您知悉、理解并同意，您通过中国杨凌农业高新科技成果博览会云上展会平台上传或传输的内容（包括但不限文字、图像、音频、视频、直播内容等各种形式的内容及其中包括的音乐、声音、台词、视觉设计、对话等所有组成部分），您授予我们及其关联方一项全国范围内、免费、非独占、不可撤销的、可再许可（通过多层次）的权利（包括但不限于复制权、翻译权、汇编权、信息网络传播权、改编权及制作衍生品、表演和展示的权利等），上述权利的使用范围包括但不限于在当前或其他网站、应用程序或终端设备等使用。您在此确认并同意，我们有权自行或许可第三方在与上述内容、中国杨凌农业高新科技成果博览会云上展会平台及中国杨凌农业高新科技成果博览会云上展会服务有关的任何宣传、推广、广告、营销和/或研究中使用和以其他方式开发内容（全部或部分）。为避免疑义，您理解并同意，上述授予的权利包括使用、复制和展示您拥有或被许可使用植入内容中的个人形象、肖像、姓名、商标、服务标志、品牌、名称、标识和公司标记以及任何其他品牌、营销或推广素材等的权利和许可。
        </div>
      </div>
      <div class="eighth-para mt">
        <div class="">8. 隐私政策</div>
        <div class="">
          8.1您在中国杨凌农业高新科技成果博览会云上展会平台服务注册的账户具有密码保护功能，以确保您基本信息资料的安全，请您妥善保管账户及密码信息。
        </div>
        <div class="">
          8.2我们努力采取各种合理的物理、电子和管理方面的安全措施来保护您的信息，使您存储在中国杨凌农业高新科技成果博览会云上展会平台中的信息和通信内容不会被泄漏、毁损或者丢失，包括但不限于SSL、信息加密存储、数据中心的访问控制。我们对可能接触到信息的员工或外包人员也采取了严格管理，包括但不限于根据岗位的不同采取不同的权限控制，与他们签署保密协议，监控他们的操作情况等措施。我们会按现有技术提供相应的安全措施来保护您的信息，提供合理的安全保障，我们将在任何时候尽力做到使您的信息不被泄漏、毁损或丢失，但同时也请您注意在信息网络上不存在绝对完善的安全措施，请妥善保管好相关信息。
        </div>
        <div class="">
          8.3您应当保管好终端、账号及密码，并妥善保管相关信息和内容。因您自身原因导致的数据丢失或被盗以及在本软件及服务中相关数据的删除或储存失败的责任由您自行承担。
        </div>
        <div class="">
          8.4其他隐私条款见《中国杨凌农业高新科技成果博览会云上展会隐私权政策》。
        </div>
      </div>
      <div class="ninth-para mt">
        <div class="">9.用户违反本协议的处理</div>
        <div class="">
          9.1如您违反本协议，我们有权独立判断并视情况采取预先警示、停止服务，删除相关内容、限制账户部分或者全部功能、终止提供服务、封禁账户等措施，对于因此而造成您无法正常使用账户及中国杨凌农业高新科技成果博览会云上展会平台服务等后果，我们不承担任何责任。我们有权在合理范围内公告处理结果，且有权根据实际情况决定是否恢复相关账户的使用。对涉嫌违反法律法规、涉嫌违法犯罪的行为，我们将保存有关记录，并有权依法向有关主管部门报告、配合有关主管部门调查、向公安机关报案等。对已删除内容我们有权不予恢复。
        </div>
        <div class="">
          9.2因您违反本协议引起第三方投诉、诉讼或索赔的，您应当自行处理并承担可能因此产生的全部法律责任。因您违反本协议导致我们及/或我们的关联方向任何第三方赔偿或遭受国家机关处罚的，您还应足额赔偿我们及/或我们关联方因此遭受的全部损失。
        </div>
      </div>
      <div class="tenth-para mt">
        <div class="">10.免责声明</div>
        <div class="">
          10.1服务将按"现状"和按"可得到"的状态提供。我们在此明确声明对服务不作任何明示或暗示的保证，包括但不限于对服务的可适用性，没有错误或疏漏，持续性，准确性，可靠性，适用于某一特定用途之类的保证，声明或承诺。
        </div>
        <div class="">
          10.2我们对服务所涉的技术和信息的有效性，准确性，正确性，可靠性，质量，稳定，完整和及时性均不作承诺和保证。
        </div>
        <div class="">
          10.3不论在何种情况下，我们均不对由于Internet连接故障，电脑，通讯或其他系统的故障，电力故障，罢工，劳动争议，暴乱，起义，骚乱，疫情、生产力或生产资料不足，火灾，洪水，风暴，爆炸，不可抗力，战争，政府行为，国际、国内法院的命令或第三方的不作为而造成的不能服务或延迟服务承担责任。
        </div>
        <div class="">
          10.4不论是否可以预见，不论是源于何种形式的行为，我们不对由以下原因造成的任何特别的，直接的，间接的，惩罚性的，突发性的或有因果关系的损害或其他任何损害（包括但不限于利润或利息的损失，营业中止，资料灭失）承担责任：
        </div>
        <div class="">10.4.1 使用或不能使用服务；</div>
        <div class="">
          10.4.2
          通过服务购买或获取任何产品，样品，数据，信息或进行交易等，或其他可替代上述行为的行为而产生的费用；
        </div>
        <div class="">10.4.3 未经授权的存取或修改数据或数据的传输；</div>
        <div class="">10.4.4 第三方通过服务所作的陈述或行为；</div>
        <div class="">10.4.5 其它与服务相关事件，包括疏忽等，所造成的损害。</div>
        <div class="">
          10.5您充分了解并同意，鉴于互联网体制及环境的特殊性，您在服务中分享的信息及个人资料有可能会被他人复制、转载、擅改或做其它非法用途；您在此已充分意识此类风险的存在，并确认此等风险应完全由您自行承担，我们对此不承担任何责任。
        </div>
        <div class="">
          10.6您了解并同意，在使用服务过程中可能存在来自任何他人的包括威胁性的、诽谤性的、令人反感的或非法的内容或行为或对他人权利的侵犯（包括知识产权）及匿名或冒名的信息的风险，基于服务使用规范所述，该等风险应由您自行承担，我们对此不承担任何责任。
        </div>
      </div>
      <div class="eleven-para mt">
        <div class="">11.法律适用及争议解决</div>
        <div class="">
          11.1本协议之解释与适用，以及与本协议有关的争议，均应依照中华人民共和国法律予以处理。凡因本协议引起的或与本协议有关的一切争议均应提交上海国际经济贸易仲裁委员会，按其现行有效的仲裁规则进行仲裁，仲裁裁决是终局的，对双方均有约束力。
        </div>
        <div class="">
          11.2如本协议的任何条款被视作无效或无法执行，则上述条款可被分离，其余部份则仍具有法律效力。
        </div>
        <div class="">
          11.3杨凌农高会筹委会于用户过失或违约时放弃本协议规定的权利的，不得视为其对用户的其他或以后同类之过失或违约行为弃权。
        </div>
        <div class="">
          11.4本协议应取代双方此前就本协议任何事项达成的全部口头和书面协议、安排、谅解和通信。
        </div>
        <div class="">
          11.5您理解并同意，杨凌农高会筹委会有权自主决定经营策略并根据业务调整情况将本协议项下的全部权利义务一并转移给其关联公司，转让将以本协议规定的方式通知，用户承诺对此不持有异议。
        </div>
      </div>
      <div class="twelve-para mt">
        <div class="">12. 有效通知</div>
        <div class="">
          12.1我们向您发出的任何通知，可采用电话、短信、电子邮件、页面公告或书面方式，且该等通知应自发送成功之日起3天后视为已向用户送达或生效。
        </div>
        <div class="">12.2您同意，您向我们发出的任何通知应发至以下地址：</div>
        <div class="focus">杨凌农高会筹委会联系方式</div>
        <div class="">
          农高会参展招商热线：+86(0)29-87036991、87036992、87036419、87031012
          传真：+86(0)29-87036995
        </div>
        <div class="">
          农业机械工程展区咨询热线：+86(0)29-87033933 传真：+86(0)29-87038560
        </div>

        <div class="">
          农高会专业观众咨询热线：+86(0)29-87035894 传真：+86(0)29-87036994。
        </div>
        <div class="">办公地址：杨凌示范区产业路中段农高会C馆</div>
        <div class="">邮政编码：712100</div>
        <div class="">联系电话：029-87036998</div>
      </div>
    </div>
    <u-back-top :scroll-top="scrollTop"></u-back-top>
  </div>
</template>

<script>
// import uaNavbar from "../../../components/ua-navbar.vue";
import Header from '../../components/Header'
export default {
  data() {
    return {
      scrollTop: 0,
    };
  },
  components: { Header }
  // onPageScroll(e) {
  // 	this.scrollTop = e.scrollTop;
  // },
};
</script>

<style lang="less" scoped>
.wrap {
  padding: 50px 80px;
  width: 100%;
  .content {
	  margin: 0 auto;
    line-height: 30px;
    max-width: 1240px;
    min-width: 1240px;
    .intro {
      .intro-title {
        text-align: center;
      }

      .intro-date {
        text-align: right;
      }
    }

    line-height: 50rpx;
    padding: 40rpx 20rpx;

    .mt {
      margin-top: 50rpx;
    }

    .focus {
      font-weight: 700;
    }

    .line {
      text-decoration: underline;
    }
  }
}
</style>
